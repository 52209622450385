.contact {
  width: 100%;
  /* height: 100%; */
  display: flex;
  /* color: white; */
  /* background-image: url("../assets/lineArt1.jpg"); */
  /* background-color: rgba(0,0,0,.5); */
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  /* margin: 3% */
}

.contact .leftSide {
  height: auto;
  width: 100%;
  margin: 5%;
  justify-content: center;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  display: flex;
  flex-direction: column;
  /* border-radius: 1000px; */
}

.contact .leftSide div{
  /* height: 40%; */
  /* flex: 10%; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
   display: flex;
  flex-direction: column;
  /* border-radius: 1000px;  */
 } 

.contact .rightSide {
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: auto;
  padding: 30px;
}

form input {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: grey;
}

.contact button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  /* appearance: unset; */
  /* text-rendering: unset; */
  background-color: #1976D2;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}

.contact button:hover {
  /* color: rgba(0, 0, 0, 0.3); */
  transition: 0.1s ease-in-out;
  cursor: pointer;
  transform: scale(1.025); 
}


@media only screen and (max-width: 650px) { 
  .contact {
    flex-direction: column;
    /* height: auto; */

  }
}