.menu {
  /* width: 100%; */
  /* height: auto; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  background-color: black;
}
/* .menu .menuTitle {
  font-family: geneva,verdana;
  font-size: 60px;
} */

.menuList {
  width: 100vw;
  height: auto;
  /* align-items: center; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* place-items: center; */
}

/* MENU ITEM STYLING */

.menuItem {
  /* border-radius: 15px; */
  width: 25%;
  height: 25%;
  /* width: 380px; */
  /* height: 380px; */
  /* margin: 20px; */
  /* box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2); */
}
.menuItem:hover {
  color: rgba(0, 0, 0, 0.3);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  transform: scale(1.025); 
}

/* .menuItem a {
    text-decoration:none;
} */

.menuItem div {
  width: 100%;
  /* height: 200px; */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .menuItem h1 {
  margin-left: 20px;
  color: rgb(38, 195, 205);
}
.menuItem p {
  margin-left: 20px;
  color: rgb(125,125,125);
} */

/* @media only screen and (max-width: 1300px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
  }
} */

@media only screen and (max-width: 800px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
    /* width: auto; */
    /* height: auto; */
    /* width: 100px;
     */
     width: 100%;
  }
  .menu {
    /* width: 600px; */
  }
  .menuItem {
    margin: 1px;
    /* width: 40%; */
    /* height: 40%; */
    width: 175px;
    height: 180px;
  }

  /* .menuItem p { */
    /* margin-left: 10px; */
    /* color: rgb(125,125,125); */
  /* } */
  
}
