.App {
  color: #ffd166
  /* width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  color: #ffd166;
  font-family: Rubix, Arial, Helvetica, sans-serif;
  background-position: center;
  background-repeat: no-repeat; */
  /* background-color: black; */
  /* transform: translatex(50%); */
  /* color: #E6A153; */
  /* background-attachment: fixed; */
  /* background-size: cover; */
}
.Moon{
  /* background-image: url("./assets/sundial.jpg"); */
  /* background-size: cover; */
}
.Cat{
  /* background-image: url("./assets/fabricBackground.jpg");
  text-align: center;
  justify-content: center;
  align-content: center; */
  background-position: center;
  background-repeat: no-repeat;
  align-items: center; 
  background-size: cover;
  width: 100%;
  height: 100%;
  /* background-image: url('https://e1.pxfuel.com/desktop-wallpaper/484/53/desktop-wallpaper-1280x1280-lights-bright-bokeh-holiday-minimalist-christmas-ipad-thumbnail.jpg'); */
}
.Land{
  /* background-image: url("https://unsplash.com/photos/a-couple-of-couches-and-a-table-in-a-room-L-VilLa6n_I"); */
  background-image: url("https://images.unsplash.com/photo-1550520293-d34b3f2e116d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
   z-index: -5;
}
.landText {
  width: 100%;
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
}

body {
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 650px) { 
  .App {
    /* background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden; */
    /* background-color: black; */
  }
  .Land{
    /* background-image: unset; */
    /* width: 100%;
    height: 100%; */
    background-image: url("./assets/bottom2.png");
    /* background-image: url("./assets/map.png"); */
    /* background-size: cover; */
    z-index: -6;
  }
}
/* body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("./assets/sundial.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 
  /* display: none; */
  /* overflow: hidden; */
  /* webkit-transform: translatex(-50%); */
  /* moz,ms,o */
  /* transform: translatex(50%); */
  /* position: absolute; */
  
  /* background-color: transparent; */
  /* position: relative; */
  /* -webkit-font0smoothing: antialiased; */