/* Inspired by this pen by Pieter Biesemans https://codepen.io/pieter-biesemans/pen/BQBWXX and My Mother Before Me project http://mymotherbeforeme.com/ */

.HoriParallax {
    font-family: Hepta Slab, sans-serif;
    font-weight: 500;
    /* background: #efecdb; */
    color: #5D4037;
  }
  /* * {
    box-sizing: border-box;
  } */
  
  /* hide scrollbar */
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  
  ::-webkit-scrollbar-button {
    width: 1px;
    height: 1px;
  }
  .external {
    overflow: hidden;
    height: 100vh;
  }
  
  .horizontal-scroll-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 83vh;
    transform: rotate(-90deg) translate3d(0,-100vh,0);
    transform-origin: right top;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    height: 100vw;
    perspective: 1px;
      transform-style: preserve-3d;
    padding-bottom: 10rem;
  }
  .img-wrapper {
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    transform-origin: 50% 50%;
    transform: rotate(90deg) translateZ(.1px) scale(0.9) translateX(0px) translateY(-3vh);
    transition: 1s;
  }
  
  .img-wrapper:hover {
   min-height: 65vh;
  }
  
  .slower {
    transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(0%) translateY(-10vh);
  }
  .slower1 {
    transform: rotate(90deg) translateZ(-.25px) scale(1.05) translateX(0%) translateY(8vh);
  }
  .slower2 {
    transform: rotate(90deg) translateZ(-.3px) scale(1.3) translateX(0%) translateY(2vh);
  }
  .slower-down {
    transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(0%) translateY(16vh) ;
  }
  .faster {
    transform: rotate(90deg) translateZ(.15px) scale(0.8) translateX(0%) translateY(14vh) ;
  }
  .faster1 {
    transform: rotate(90deg) translateZ(.05px) scale(0.8) translateX(0%) translateY(10vh) ;
  }
  .fastest {
    transform: rotate(90deg) translateZ(.22px) scale(0.7) translateX(-10vh) translateY(-15vh) ;
  }
  .vertical {
     transform: rotate(90deg) translateZ(-.15px) scale(1.15) translateX(0%) translateY(0%) ;
  }
  .last {
    transform: rotate(90deg) translateZ(-.2px) scale(1.1) translateX(25vh) translateY(-8vh) ;
  }
  .scroll-info, header {
    position: absolute;
    left: 1rem;
  }
  
  
  header {
    /* bottom: 1rem; */
  }
  .scroll-info {
    top: 1rem;
  }
  .HoriParallax a {
    color: inherit;
    font-weight: 500;
    
  }
  .HoriParallax h1 {
    font-weight: 300;
    font-size: 1rem;
  }
  
  .img-wrapper a {
    overflow: hidden;
    display: block;
    padding: 1vh ;
    background: #efecdb;
    box-shadow: 0 10px 50px #5f2f1182;
  }
  .HoriParallax img {
    max-width: 45vh;
    max-height: 50vh;
    transition: .5s;
    vertical-align: top;
    /* filter: saturate(40%) sepia(30%) hue-rotate(5deg); */
  }
  .HoriParallax a:hover img {
    /* filter: none; */
  }
  .HoriParallax p {
    margin: 0;
  }
  .scroll-info {
    display: flex;
    align-items: center;
  }
  /* .icon svg {
      width: 50px;
      fill: currentcolor;
  } */

  .HoriParallax svg {
    /* position: relative;  */
    /* display: block; */
    width: 500px;
    height: 500px;
    text-align: center;
    line-height: 63px;
    /* background: #333; */
    border-radius: 50%;
    font-size: 30px;
    margin-left: 4%;
    color: #666;
    transition: .5s;
  }
  
  .HoriParallax svg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ffee10;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
  }
  
  .HoriParallax svg:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #ffee10;
  }
  
  .HoriParallax svg:hover, .communityRight img:hover {
    /* background-color: #07090a; */
    /* cursor: pointer; */
    transition: 0.3s ease-in;
  
    color: #ffee10;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
  }